<template>
    <div class="pledge">
        <h5header :name="$t('reward.desc38')" :right="false" />
        <div class="pledge-con">
            <div class="pledge-tab flex">
                <div class="table-btn flex">
                    <div :class="{ tabact: act == 1 }" @click="handlerNav(1)">{{ $t('reward.desc30') }}</div>
                    <!-- <div :class="{ tabact: act == 2 }" @click="handlerNav(2)">{{ $t('reward.desc29') }}</div> -->
                </div>
                <!-- <div class="tab-icon flex" @click="showNotice = true">
                    <img src="../../public/images/reward/icon7.png" alt="" />
                    <div>{{ $t('reward.desc17') }}</div>
                </div> -->
            </div>
            <div class="pledage-rules" v-if="act == 1">
                <!-- <img src="../../public/images/reward/icon8.png" alt="" @click="showNotice = true" class="img5" />
                <img src="../../public/images/reward/mao.png" alt="" class="img1" />
                <img src="../../public/images/reward/xue.svg" alt="" class="img2" />
                <img src="../../public/images/reward/xue.svg" alt="" class="img3" />
                <img src="../../public/images/reward/xue.svg" alt="" class="img4" /> -->
                <div>{{ $t('reward.desc40') }} <label>{{ weekTime1 }} (GST)</label>
                </div>
                <div>{{ $t('reward.desc41') }}</div>
                <div>{{ $t('reward.desc42') }}</div>
                <div>{{ $t('reward.desc43') }}</div>
            </div>
            <div class="pledage-rules" v-else>
                <!-- <img src="../../public/images/reward/icon8.png" alt="" @click="showNotice = true" class="img5" />
                <img src="../../public/images/reward/mao.png" alt="" class="img1" />
                <img src="../../public/images/reward/xue.svg" alt="" class="img2" />
                <img src="../../public/images/reward/xue.svg" alt="" class="img3" />
                <img src="../../public/images/reward/xue.svg" alt="" class="img4" /> -->
                <div>{{ $t('reward.desc67') }} <label>{{ configInfo2.startTime }} - {{ configInfo2.endTime }} (GST)</label>
                </div>
                <div>{{ $t('reward.desc68') }}</div>
                <div>{{ $t('reward.desc69') }}<span v-for="item in configInfo2.list">{{ item.levelName }} <label>{{
                    item.amount }} {{ item.coinName }}</label>，</span></div>
                <p>{{ $t('reward.desc70') }}</p>
            </div>
            <div class="pledge-title flex">
                <img src="../../public/images/new/inviteicon6.png" alt="">
                <div v-if="act==1">{{ $t('reward.desc44') }}</div>
                <div v-else>{{ $t('reward.desc161') }}</div>
                <img src="../../public/images/new/inviteicon5.png" alt="">
            </div>
            <div class="planet-list flex">
                <div class="flexcenter" :class="{ btnact: planetId == item.typeId }" v-for="item in configInfo2.list" :key="item.typeId"
                    @click="handlerTab(item.typeId)">{{
                        item.levelName }}</div>
            </div>
            <div class="table" v-if="act == 1">
                <div class="table-thead flex">
                    <div>{{ $t('reward.desc45') }}</div>
                    <div class="flex">{{ $t('reward.desc46') }} <el-tooltip effect="dark" placement="bottom">
                            <template #content>
                                <div>{{ $t('reward.desc51') }}</div>
                            </template>
                            <img src="../../public/images/new/tips.png" alt="" />
                        </el-tooltip></div>
                    <div>{{ $t('reward.desc47') }}</div>
                    <div>{{ $t('reward.desc48') }}</div>
                    <div>{{ $t('reward.desc49') }}</div>
                    <div>{{ $t('reward.desc50') }}</div>
                </div>
                <div class="table-tbody">
                    <div class="tbody-info flex" v-for="item in list" :key="item.id">
                        <div>
                            <img src="../../public/images/reward/parkicon1.png" alt="" v-if="item.park == 1" />
                            <img src="../../public/images/reward/parkicon2.png" alt="" v-else-if="item.park == 2" />
                            <img src="../../public/images/reward/parkicon3.png" alt="" v-else-if="item.park == 3" />
                            <span v-else>{{ item.park }}</span>
                        </div>
                        <div>{{ item.sellCount }}</div>
                        <div>{{ item.levelName }}</div>
                        <div>{{ item.name }}</div>
                        <div v-if="item.landStatus == 2">{{ $t('reward.desc63') }}</div>
                        <div v-else-if="item.landStatus == 1">{{ $t('reward.desc65') }}</div>
                        <div v-else>{{ $t('reward.desc66') }}</div>
                        <div>{{ dealAddress(item.userName) }}</div>
                    </div>
                    <div class="no-data" v-if="showNull">
                        <img src="../../public/images/no-data.png" alt="" />
                        <span>{{ $t("record.desc13") }}</span>
                    </div>
                </div>
                <div class="planet-page">
                    <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
                        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
                    </el-pagination>
                </div>
            </div>
            <div class="table" v-else>
                <div class="table-thead flex">
                    <div>{{ $t('reward.desc45') }}</div>
                    <div>{{ $t('reward.desc64') }}</div>
                    <div>{{ $t('reward.desc47') }}</div>
                    <div>{{ $t('reward.desc48') }}</div>
                    <div>{{ $t('reward.desc49') }}</div>
                    <div>{{ $t('reward.desc50') }}</div>
                </div>
                <div class="table-tbody">
                    <div class="tbody-info flex" v-for="item in list" :key="item.id">
                        <div>
                            <img src="../../public/images/reward/parkicon1.png" alt="" v-if="item.park == 1" />
                            <img src="../../public/images/reward/parkicon2.png" alt="" v-else-if="item.park == 2" />
                            <img src="../../public/images/reward/parkicon3.png" alt="" v-else-if="item.park == 3" />
                            <span v-else>{{ item.park }}</span>
                        </div>
                        <div style="color:#C9FA5B">{{ item.sellCount }}/{{ item.totalCount }}</div>
                        <div>{{ item.levelName }}</div>
                        <div>{{ item.name }}</div>
                        <div>{{ $t('reward.desc63') }}</div>
                        <div>{{ dealAddress(item.userName) }}</div>
                    </div>
                    <div class="no-data" v-if="showNull">
                        <img src="../../public/images/no-data.png" alt="" />
                        <span>{{ $t("record.desc13") }}</span>
                    </div>
                </div>
                <div class="planet-page">
                    <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
                        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
                    </el-pagination>
                </div>
            </div>
            <div class="h5list">
                <div class="list-info" v-for="item in list" :key="item.id">
                    <div class="info flex">
                        <div>{{ $t('reward.desc45') }}</div>

                        <p> <img src="../../public/images/reward/parkicon1.png" alt="" v-if="item.park == 1" />
                            <img src="../../public/images/reward/parkicon2.png" alt="" v-else-if="item.park == 2" />
                            <img src="../../public/images/reward/parkicon3.png" alt="" v-else-if="item.park == 3" />
                            <span v-else>{{ item.park }}</span>
                        </p>
                    </div>
                    <div class="info flex" v-if="act == 1">
                        <div class="flex">{{ $t('reward.desc46') }} <el-tooltip effect="dark" placement="bottom">
                                <template #content>
                                    <div>{{ $t('reward.desc51') }}</div>
                                </template>
                                <img src="../../public/images/new/tips.png" alt="" />
                            </el-tooltip></div>

                        <p>{{ item.sellCount }}</p>
                    </div>
                    <div class="info flex" v-else>
                        <div>{{ $t('reward.desc64') }}</div>
                        <p style="color:#C9FA5B">{{ item.sellCount }}/{{ item.totalCount }}</p>
                    </div>
                    <div class="info flex">
                        <div>{{ $t('reward.desc47') }}</div>

                        <p>{{ item.levelName }}</p>
                    </div>
                    <div class="info flex">
                        <div>{{ $t('reward.desc48') }}</div>

                        <p>{{ item.name }}</p>
                    </div>
                    <div class="info flex">
                        <div>{{ $t('reward.desc49') }}</div>

                        <p v-if="item.landStatus == 2">{{ $t('reward.desc63') }}</p>
                        <p v-else-if="item.landStatus == 1">{{ $t('reward.desc65') }}</p>
                        <p v-else>{{ $t('reward.desc66') }}</p>
                    </div>
                    <div class="info flex">
                        <div>{{ $t('reward.desc50') }}</div>

                        <p>{{ dealAddress(item.userName) }}</p>
                    </div>
                </div>
                <div class="no-data" v-if="showNull">
                    <img src="../../public/images/no-data.png" alt="" />
                    <span>{{ $t("record.desc13") }}</span>
                </div>
                <div class="planet-page">
                    <el-pagination layout="prev, pager, next" :pager-count="5" :page-size="10" :current-page="page"
                        :total="totals" :hide-on-single-page="true" @current-change="handlerCurrent">
                    </el-pagination>
                </div>
            </div>
        </div>
        <!-- 活动规则 -->
        <van-popup v-model:show="showNotice" :close-on-click-overlay="false">
            <div class="notice-con">
                <img src="../../public/images/new/closeicon2.png" alt="" class="close" @click="showNotice = false" />
                <div class="notice-name">{{ $t("reward.desc17") }}：</div>

                <div class="rules-list">
                    <div>{{ $t('reward.desc54') }}</div>
                    <div>{{ $t('reward.desc55') }}{{ configInfo2.startTime }} - {{ configInfo2.endTime }}(GST)</div>
                    <div>{{ $t('reward.desc56') }}</div>
                    <div>{{ $t('reward.desc57') }} <span v-for="item in configInfo2.list">{{ item.levelName }} <label>{{
                        item.amount }} {{ item.coinName }}</label>，</span></div>
                    <div>{{ $t('reward.desc58') }}</div>
                    <div>{{ $t('reward.desc59') }}<span v-for="item in configInfo2.list">{{ item.levelName }} <label>{{
                        item.amount }} {{ item.coinName }}</label>，</span></div>
                    <div>{{ $t('reward.desc60') }}{{ configInfo2.coinName }}</div>
                    <div>{{ $t('reward.desc61') }}{{ configInfo2.awardAccountTypeName }}</div>
                    <div>{{ $t('reward.desc62') }}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import h5header from "@/componets/h5header.vue";
export default {
    components: {
        h5header
    },
    data() {
        return {
            showNotice: false,
            act: 1,

            typeList: [],
            planetId: 1,
            page: 1,
            list: [],
            totals: 0,
            showNull: false,
            configInfo2: {},
            weekTime1: ''
        }
    },
    mounted() {
        this.$post(this.URL.reward.marketConfig, {}).then(res => {
            if (res.code == 0) {
                res.data.coinName = res.data.list[0].coinName;
                if (res.data.accountType == 1) {
                    res.data.awardAccountTypeName = this.$t('new.desc7')
                } else if (res.data.accountType == 2) {
                    res.data.awardAccountTypeName = this.$t('new.desc8')
                } else {
                    res.data.awardAccountTypeName = this.$t('new.desc9')
                }
                this.configInfo2 = res.data;
                this.planetId = this.configInfo2.list[0].typeId;
                this.getlist()
                let weekday = new Date(`${this.getThisSunday()} 23:59:59`), weekdaytime = weekday.getTime(), todayTime = new Date().getTime();

                if (todayTime > localStorage.getItem('startTime') && localStorage.getItem('startTime') != null) {
                    if (weekdaytime > this.configInfo2.endTimeStamp) {
                        this.weekTime1 = `${this.getWeek(new Date(), 0, 0)} 00:00:00 - ${this.configInfo2.endTime}`;
                        return;
                    }
                    this.weekTime1 = `${this.getWeek(new Date(), 0, 0)} 00:00:00 - ${this.getThisSunday()} 23:59:59`;
                } else {
                    this.weekTime1 = `${this.common.formatDate(this.configInfo2.startTimeStamp)} - ${this.getThisSunday()} 23:59:59`;
                    localStorage.setItem('startTime', weekdaytime)

                }
            }
        })
        this.act = this.$route.query.act;

        // this.$post(this.URL.star.type, {}).then(res => {
        //     if (res.code == 0) {
        //         res.data.shift();
        //         this.typeList = res.data;
        //         this.planetId = this.typeList[0].id;
        //         this.getlist()
        //     }
        // })
    },
    methods: {
        getWeek(n, type) { // 周
            let now = new Date()
            let day = now.getDay() //返回星期几的某一天;
            if (!type) {
                if (n == 1) {
                    let dayNumber = day == 0 ? 0 : 7 - day
                    now.setDate(now.getDate() + dayNumber + 1)
                } else if (n == -1) {
                    let dayNumber = day == 0 ? 6 : day - 1
                    now.setDate(now.getDate() - dayNumber - 7)
                } else {
                    let dayNumber = day == 0 ? 6 : day - 1
                    now.setDate(now.getDate() - dayNumber)
                }
            } else {
                if (n == 1) {
                    let dayNumber = day == 0 ? 0 : 7 - day
                    now.setDate(now.getDate() + dayNumber + 1 + 6) // 在周开始的日期上+6天=周结束
                } else if (n == -1) {
                    let dayNumber = day == 0 ? 6 : day - 1
                    now.setDate(now.getDate() - dayNumber - 7 + 6)
                } else {
                    let dayNumber = day == 0 ? 0 : 7 - day
                    now.setDate(now.getDate() + dayNumber)
                }
            }
            let date = now.getDate()
            let month = now.getMonth() + 1

            let s = now.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (date < 10 ? '0' + date : date)
            return s
        },
        getThisSunday() {
            var date = new Date();

            let w = date.getDay();//获取一下今天是周几
            let delta = 7 - w;//算算差几天到周日
            date.setDate(date.getDate() + delta);
            date = date.toJSON()
            date = date.substring(0, 10)
            return date;
        },
        handlerNav(i) {
            this.act = i;
            this.page = 1;
            this.showNull = false;
            this.list = [];
            this.planetId = this.typeList[0].id;
            this.getlist();
        },
        handlerTab(id) {
            this.page = 1;
            this.showNull = false;
            this.list = [];
            this.planetId = id;
            this.getlist();
        },
        handlerCurrent(val) {
            this.list = [];
            this.page = val;
            this.getlist();
        },
        dealAddress(str) {
            if (str && str.length > 3) {
                return (
                    str.substring(0, 2) +
                    "****" +
                    str.substring(str.length - 2, str.length)
                );
            } else {
                return str.substring(0, 2) +
                    "****" +
                    str.substring(str.length - 1, str.length);
            }
        },
        getlist() {
            let postUrl = '';
            this.act == 1 ? postUrl = this.URL.reward.planetList : postUrl = this.URL.reward.marketOut
            this.$post(postUrl, {
                page: this.page,
                pageSize: 10,
                typeId: this.planetId

            }).then(res => {
                if (res.code == 0) {
                    this.act == 1 ? this.totals = res.data.totalCount : this.totals = res.data.total;

                    this.act == 1 ? this.list = res.data.list : this.list = res.data.records;
                    if (this.totals != 0) {
                        this.list.forEach((e, i) => {
                            let page=this.page -1;
                            this.page == 1 ? e.park = (i + 1) : e.park = (page * 10) + (i + 1)
                        });
                    }
                    if (this.totals == 0) {
                        this.showNull = true;
                    }
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.pledge {
    padding: 126px 0 180px;
    background: url('../../public/images/reward/banner.png') top no-repeat;
    background-size: 100% auto;

    .pledge-con {
        width: 1240px;
        margin: 0 auto;

        .h5list {
            display: none;
        }

        .pledge-tab {
            border-bottom: 1px solid rgba(238, 238, 238, 0.2);

            .table-btn {
                flex: 1;
                margin-top: 10px;
                font-size: 16px;

                div {
                    padding-bottom: 24px;
                    color: rgba(255, 255, 255, 0.6);
                    cursor: pointer;
                    margin-right: 36px;

                    &:last-child {
                        margin-right: 0;
                    }

                    &:hover {
                        color: #C9FA5B;
                        border-bottom: 1px solid #C9FA5B;
                    }
                }

                .tabact {
                    color: #C9FA5B;
                    border-bottom: 1px solid #C9FA5B;
                }
            }

            .tab-icon {
                height: 36px;
                padding: 0 20px;
                background: #181818;
                border-radius: 15px;
                cursor: pointer;

                img {
                    width: 20px;
                    height: 20px;
                    margin-top: 8px;
                }

                div {
                    margin-left: 4px;
                    font-size: 13px;
                    color: #FFFFFF;
                    line-height: 36px;
                }
            }
        }

        .pledage-rules {
            position: relative;
            margin-top: 58px;
            padding: 30px 32px;
            background: rgba(0, 0, 0, 0.31);
            font-size: 16px;
            color: #D3CCCC;
            border: 1px solid rgba(255, 255, 255, 0.20);

            .img1 {
                position: absolute;
                top: -90px;
                left: -69px;
                width: 172px;
                height: 172px;
            }

            .img2 {
                position: absolute;
                top: -30px;
                left: 50%;
                width: 19px;
                height: 19px;
                transform: translateX(-50%);
            }

            .img3 {
                position: absolute;
                bottom: -36px;
                left: 429px;
                width: 48px;
                height: 48px;
            }

            .img4 {
                position: absolute;
                bottom: -62px;
                right: -30px;
                width: 148px;
                height: 148px;
            }

            .img5 {
                display: none;
            }

            div {
                position: relative;
                margin-bottom: 13px;
                text-indent: 30px;

                &:last-child {
                    margin-bottom: 0;
                }

                label {
                    color: #C9FA5B;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 6px;
                    left: 0;
                    width: 12px;
                    height: 12px;
                    background: url('../../public/images/reward/icon6.png') center no-repeat;
                    background-size: 100% 100%
                }
            }

            p {
                font-size: 13px;
                color: #E15757;
            }
        }

        .pledge-title {
            margin: 70px 0 46px;
            justify-content: center;

            img {
                flex: 0 0 95px;
                width: 95px;
                height: 17px;
                margin-top: 11px;
            }

            div {
                padding: 0 16px;
                font-size: 40px;
                color: #C9FA5B;
                font-weight: 600;
                line-height: 40px;
            }
        }

        .planet-list {
            margin-bottom: 24px;

            div {
                flex: 0 0 106px;
                width: 106px;
                height: 36px;
                margin-right: 16px;
                background: url('../../public/images/reward/btnbg4.svg') center no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.6);
                text-align: center;

                &:last-child {
                    margin-right: 0;
                }

                &:hover {
                    color: #000000;
                    background: url('../../public/images/reward/btnbg5.svg') center no-repeat;
                    background-size: 100% 100%;
                }
            }

            .btnact {
                color: #000000;
                background: url('../../public/images/reward/btnbg5.svg') center no-repeat;
                background-size: 100% 100%;
            }
        }

        .table-thead {
            padding: 20px 24px;
            background: #181818;

            div {
                flex: 1;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.6);
                line-height: 14px;

                &:first-child {
                    flex: 0 0 90px;
                }

                &:last-child {
                    flex: 0 0 100px;
                    text-align: right;
                }

                img {
                    margin-left: 4px;
                    cursor: pointer;
                    width: 14px;
                    height: 14px;
                }
            }
        }

        .tbody-info {
            padding: 20px 24px;
            border-bottom: 1px solid #1A1A1A;

            div {
                flex: 1;
                font-size: 14px;
                color: #fff;
                line-height: 14px;

                &:first-child {
                    flex: 0 0 90px;
                }

                &:last-child {
                    flex: 0 0 100px;
                    text-align: right;
                }

                img {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }

}

.planet-page {
    margin-top: 40px;
    padding: 0;

    ::v-deep .el-pagination {
        button {
            background-color: rgba(255, 255, 255, 0);
            color: #666666;
            font-size: 14px;
        }

        .el-pager {
            li {
                background: rgba(255, 255, 255, 0);
                font-size: 14px;
                color: #666666;
            }

            .is-active {
                background: #c9fa5b !important;
                border-radius: 4px !important;
                color: #000000 !important;
            }
        }
    }
}

.no-data {
    width: 100%;
    padding: 130px 0;

    img {
        display: block;
        width: 120px;
        height: 120px;
        margin: 0 auto 16px;
    }

    span {
        display: block;
        margin: 0 auto;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #a1a1a1;
    }
}

::v-deep .van-popup {
    border-radius: 20px;
    overflow-y: initial;
}

.notice-con {
    position: relative;
    width: 500px;
    padding: 40px 0;
    max-height: 653px;
    background: url("../../public/images/reward/rulesbg.png") top no-repeat;
    background-size: 100% 653px;

    &::after {
        content: '';
        position: absolute;
        top: -30px;
        right: 45px;
        width: 154px;
        height: 158px;
        background: url("../../public/images/reward/rules.png") top no-repeat;
        background-size: 100% 100%;
    }

    .close {
        position: absolute;
        bottom: -58px;
        left: 50%;
        width: 38px;
        height: 38px;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .notice-name {
        padding: 20px 40px 0;
        margin-bottom: 32px;
        font-size: 24px;
        color: #c9fa5b;
        line-height: 24px;
    }

    .notice-title {
        padding: 0 40px 12px;
        font-size: 18px;
        color: #ffffff;
    }


    .rules-list {
        max-height: 330px;
        overflow-y: scroll;
        padding: 0 40px;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.8);

        div {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            label {
                color: #C9FA5B;
            }
        }

        &::-webkit-scrollbar {
            width: 0px; //修改滚动条宽度
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0;
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            background: #666666;
        }
    }

    .notice-time {
        padding: 23px 40px 0;
        font-size: 16px;
        text-align: right;
        color: rgba(255, 255, 255, 0.6);
    }

    .notice-btn {
        margin: 23px auto 0;
        width: 418px;
        height: 52px;
        background: url("../../public/images/new/btnbg25.png") center no-repeat;
        background-size: 100% 100%;
        cursor: pointer;
        font-size: 18px;
        color: #000000;
        text-align: center;
        line-height: 52px;
    }
}

@media (max-width:1200px) {
    .pledge {
        padding: 68px 16px 70px;

        .pledge-con {
            width: 100%;

            .pledage-rules {
                .img1 {
                    left: -51px;
                }

                .img3 {
                    left: 260px;
                }

                .img4 {
                    right: -16px;
                    width: 100px;
                    height: 100px;
                }
            }
        }
    }
}

@media (max-width:767px) {
    .pledge {
        padding: 56px 16px;

        .pledge-con {
            .pledge-tab {
                .tab-icon {
                    display: none;
                }

                .table-btn {
                    margin-top: 0;

                    div {
                        padding-bottom: 14px;
                        margin-right: 16px;
                    }
                }
            }

            .planet-list {
                div {
                    font-size: 14px;
                }
            }

            .pledage-rules {
                padding: 16px;

                div {
                    text-indent: 15px;

                    &::after {
                        top: 6px;
                        transform: translateY(0);
                    }
                }

                .img1 {
                    top: -59px;
                    left: -40px;
                    width: 112px;
                    height: 112px;
                }

                .img2,
                .img3 {
                    display: none;
                }

                .img4 {
                    bottom: -57px;
                    width: 81px;
                    height: 81px;
                }

                .img5 {
                    position: absolute;
                    top: -48px;
                    right: 0;
                    width: 32px;
                    height: 32px;
                    display: block;
                }
            }

            .pledge-title {
                margin: 67px 0 24px;

                div {
                    padding: 0 8px;
                    font-size: 20px;
                    line-height: normal;
                }

                img {
                    flex: 0 0 75px;
                    width: 70px;
                    height: 13px;
                    margin-top: 7px;
                }
            }

            .planet-list {
                width: auto;
                overflow-x: scroll;
            }

            .table {
                display: none;
            }

            .h5list {
                display: block;

                .list-info {
                    margin-bottom: 10px;
                    padding: 16px;
                    border-radius: 8px;
                    background: #181818;

                    .info {
                        margin-bottom: 14px;
                        line-height: 22px;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        div {
                            flex: 0 0 60%;
                            font-size: 14px;
                            color: rgba(255, 255, 255, 0.6);

                            img {
                                width: 14px;
                                height: 14px;
                                margin: 4px 0 0 4px;
                            }
                        }

                        p {
                            flex: 0 0 40%;
                            text-align: right;
                            font-size: 14px;
                            color: #fff;

                            img {
                                width: 22px;
                                height: 22px;
                            }
                        }
                    }
                }
            }
        }
    }

    .no-data {
        padding: 50px 0;
    }

    .notice-con {
        width: 298px;
        padding: 20px 0;

        &::after {
            display: none;
        }

        .close {
            bottom: -40px;
            width: 28px;
            height: 28px;
        }

        .notice-name {
            padding: 0 16px;
            margin-bottom: 12px;
            font-size: 16px;
        }

        .notice-title {
            padding: 0 16px 8px;
            font-size: 14px;
        }

        .rules-list {
            padding: 0 16px;
            font-size: 14px;

            div {
                margin-bottom: 8px;
            }
        }

        .notice-time {
            padding: 23px 16px 0;
            font-size: 14px;
        }

        .notice-btn {
            width: 262px;
            height: 40px;
            margin: 12px auto 0;
            background: url("../../public/images/new/btnbg26.png") top no-repeat;
            background-size: 100% 100%;
            font-size: 14px;
            line-height: 38px;
        }
    }
}
</style>